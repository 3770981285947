import * as Sentry from "@sentry/node";
import { CombinedError } from "@urql/core";
import config from "../config";

export const initialiseSentry = () => {
  if (config.sentryDsn == null) return;

  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    enabled: process.env.NODE_ENV === "production",
  });
};

export const logGraphqlError = (error: CombinedError) => {
  Sentry.captureException(error, (scope) => {
    scope.setContext("graphql", {
      errors: JSON.stringify(error.graphQLErrors, null, 2),
    });
    return scope;
  });
};
