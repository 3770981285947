import React from "react";
import {
  createClient,
  Provider,
  dedupExchange,
  fetchExchange,
  errorExchange,
  cacheExchange,
} from "urql";
import { CombinedError, makeOperation, Operation } from "@urql/core";
import config from "../config";
import { useAuthContext } from "./authentication";
import { authExchange } from "@urql/exchange-auth";
import { devtoolsExchange } from "@urql/devtools";
import SingletonRouter from "next/router";
import { useFlashMessages } from "./flash-messages";
import {
  hasGraphQLError,
  hasGraphQLPaths,
  hasNetworkError,
} from "./graphql-errors";
import { logGraphqlError } from "./sentry";
import { getTogglAccountsLoginUrl } from "./toggl-accounts-urls";

export default function GraphQLProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const authContext = useAuthContext();
  const { addMessage } = useFlashMessages();
  const client = React.useMemo(() => {
    return createClient({
      url: `${config.apiUrl}/graph`,
      fetchOptions: {
        credentials: config.sharedAuthEnabled ? "include" : undefined,
      },
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        cacheExchange,
        errorExchange({
          onError: (error: CombinedError, operation: Operation) => {
            if (hasNetworkError(error)) {
              // This seems to be the only way to parse what query was made
              // for network errors.
              if (operation.query.loc?.source.body.includes("LockedPage")) {
                return;
              }
              addMessage({ type: "network_error" });
            } else if (hasGraphQLError(error, "Forbidden")) {
              // Some forbidden errors shouldn't raise a Flash Message
              if (
                !hasGraphQLPaths(error, [
                  "createExpertByEmail",
                  "deleteExpertChallenge",
                ])
              ) {
                logGraphqlError(error);
                addMessage({ type: "forbidden_error" });
              }
            } else if (hasGraphQLError(error, "NotFound")) {
              // createExpertByEmail 404 should be handled on the form
              if (!hasGraphQLPaths(error, "createExpertByEmail")) {
                addMessage({ type: "not_found_error" });
              }
            } else if (hasGraphQLError(error, "InvalidInput")) {
              logGraphqlError(error);
              addMessage({ type: "invalid_input_error" });
            } else if (hasGraphQLError(error, "Unknown")) {
              // Queries for expert will always fail when running as admin
              if (
                hasGraphQLPaths(error, "expert") &&
                SingletonRouter.pathname.includes("admin")
              ) {
                return;
              }
              logGraphqlError(error);
              addMessage({ type: "server_error" });
            } else if (hasGraphQLError(error, "Unauthorized")) {
              window.location.assign(getTogglAccountsLoginUrl());
            } else {
              logGraphqlError(error);
            }
          },
        }),
        ...(!config.sharedAuthEnabled
          ? [
              authExchange({
                getAuth: async ({ authState }) => {
                  if (!authState) {
                    return authContext?.token;
                  }
                  authContext?.clearToken();
                  SingletonRouter.push("/");
                  return null;
                },
                addAuthToOperation: ({ authState, operation }) => {
                  if (!authState) {
                    return operation;
                  }
                  const fetchOptions =
                    typeof operation.context.fetchOptions === "function"
                      ? operation.context.fetchOptions()
                      : operation.context.fetchOptions || {};
                  return makeOperation(operation.kind, operation, {
                    ...operation.context,
                    fetchOptions: {
                      ...fetchOptions,
                      headers: {
                        Authorization: `Bearer ${authState}`,
                        ...fetchOptions.headers,
                      },
                    },
                  });
                },
                didAuthError: ({ error }) => {
                  return hasGraphQLError(error, "Unauthorized");
                },
              }),
            ]
          : []),
        fetchExchange,
      ],
    });
  }, [addMessage, authContext]);

  return <Provider value={client}>{children}</Provider>;
}
