import React from "react";
import { default as App, AppProps, AppContext } from "next/app";
import { ReactQueryDevtools } from "react-query-devtools";
import { FlashMessageProvider } from "../utils/flash-messages";
import { StorageAuthProvider } from "../utils/authentication";
import { FlashMessages, IntercomProvider } from "../components";
import GraphQLProvider from "../utils/graphql";
import { initialiseSentry } from "../utils/sentry";
import "../styles/globals.css";
import { GoogleReCaptchaProvider } from "../components/google-recaptcha-provider";

initialiseSentry();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <StorageAuthProvider>
        <FlashMessageProvider>
          <GraphQLProvider>
            <IntercomProvider>
              <GoogleReCaptchaProvider>
                <Component {...pageProps} />
              </GoogleReCaptchaProvider>
              <FlashMessages />
            </IntercomProvider>
          </GraphQLProvider>
        </FlashMessageProvider>
      </StorageAuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const props = await App.getInitialProps(context);
  return { ...props };
};

export default MyApp;
